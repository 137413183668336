import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Sidebar extends Component {

  click() {
    this.props.closeNav();
  }
  render() {
    return (
      <div className="border-right bg-dark" id="sidebar-wrapper">
        <div className="sidebar-heading" ></div>
        <div className="list-group list-group-flush">
          <Link to="/upload" onClick={this.click.bind(this)} className="list-group-item list-group-item-action bg-dark" style={{ color: "#ffffff" }}><i className="fa fa-upload"></i><span> Carregar Firmware </span></Link>
          <Link to="/update" onClick={this.click.bind(this)} className="list-group-item list-group-item-action bg-dark" style={{ color: "#ffffff" }}><i className="fa fa-download"></i><span> Atualizar Placa</span></Link>
          <Link to="/status" onClick={this.click.bind(this)} className="list-group-item list-group-item-action bg-dark" style={{ color: "#ffffff" }}><i className="fa fa-history"></i><span> Status de Atualizações</span></Link>
        </div>
      </div>
    );
  }
}

export default Sidebar;