import axios from 'axios'
import { Paths } from '../constants'
import { UserService } from '../services'

class HttpService {
	login(username, password) {
		const url = this._getUrl('auth-user')

		return axios({ method: 'post', url, data: { login: username, password } })
	}

	loginSecondStep(username, password, customerId) {
		const url = this._getUrl(`auth-user/token/${customerId}`)

		return axios({ url, method: 'post', data: { login: username, password } })
	}

	getUsername() {
		const url = this._getUrl('systeminfo/userinformations')
		const token = UserService.getToken()

		return axios({ url, headers: { Authorization: token } })
	}

	getVehicles(serial) {
		const search = {
			pageStart: 0,
			pageEnd: 19,
			isExactQuery: false,
			isNewData: true,
			fieldTerms: {
				status: ['PANIC', 'STOPPED_AND_ON', 'MOVING', 'OFF', 'WITHOUT_TRANSMISSION'],
				customState: [],
				equipment: `${serial}`,
				vehicleDisabled: false,
			},
			fieldTermsOperator: "AND",
		}
		const fields = ['vehicleId']
		const url = this._getUrl('vehicleMonitoring/search')
		const token = UserService.getToken()

		return axios({ url, method: 'post', data: search, params: { fields }, headers: { Authorization: token } })
	}

	hasPeripheralCAN(serial) {
		const search = {
			pageStart: 0,
			pageEnd: 19,
			isExactQuery: false,
			isNewData: true,
			fieldTerms: {
				serial: `${serial}`
			},
			fieldTermsOperator: "AND",
		}
		const fields = ['id', 'type']
		const url = this._getUrl('peripheral/search')
		const token = UserService.getToken()

		return axios({ url, method: 'post', data: search, params: { fields }, headers: { Authorization: token } })
	}

	getVersions() {
		const type = 'CAN'
		const url = this._getUrl('peripheral/firmware/versions')
		const token = UserService.getToken()

		return axios({ url, params: { type }, headers: { Authorization: token } })
	}

	updateFirmware(vehicleId, version, type) {
		const url = this._getUrl('peripheral/firmware/update')
		const token = UserService.getToken()

		return axios({ url, method: 'post', data: [vehicleId], params: { version, type }, headers: { Authorization: token } })
	}

	uploadFirmware(firmware, version, type) {
		const url = this._getUrl('peripheral/firmware/upload')
		const token = UserService.getToken()

		return axios({ url, method: 'post', data: firmware, headers: { 'content-type': 'multipart/form-data', Authorization: token }, params: { version, type } })
	}

	getLastUpdateStatus(vehicleId, type) {
		const url = this._getUrl('peripheral/firmware/detail')
		const token = UserService.getToken()

		return axios({ url, params: { vehicleId, type }, headers: { Authorization: token } })
	}

	getUpdateByStatus(vehicleId, type, status) {
		const url = this._getUrl('peripheral/firmware/detail')
		const token = UserService.getToken()

		return axios({ url, params: { vehicleId, type, status }, headers: { Authorization: token } })
	}

	sendSms(serial, command) {
		const url = this._getUrl('sms/serial')
		const data = { serials: [serial], command, model: "LMU_2630" }
		const token = UserService.getToken()

		return axios({ url, method: 'post', data, headers: { Authorization: token } })
	}

	getEquipmentInformation(serial) {
		const url = this._getUrl(`equipment/configInfo`)
		const token = UserService.getToken()

		return axios({ url, params: { serial }, headers: { Authorization: token } })
	}

	getUserPermissions() {
		const url = this._getUrl('systeminfo/userpermissions')
		const token = UserService.getToken()

		return axios({ url, headers: { Authorization: token } })
	}

	_getUrl(url) {
		return `${Paths.TRIX_PROD}/${url}`
	}

	_getPulsUrl(url) {
		return `${Paths.CALAMP_PULS}/${url}`
	}
}

export default new HttpService()