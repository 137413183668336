import React, { Component } from 'react';
import { UserService } from '../services';

class Default extends Component {

    componentDidMount() {
        if (!UserService.hasLoggedUser())
            this.props.history.push('/login')

    }

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <h3>
                        Página não encontrada
                    </h3>
                </div>
            </React.Fragment>
        );
    }
}

export default Default;