import { createHashHistory } from 'history'
export const history = createHashHistory()

class UserService {
	getToken() {
		return localStorage.getItem('token')
	}

	saveToken(token) {
		return localStorage.setItem('token', token)
	}

	saveLoggedUser(user) {
		const encodedUser = this._encodeUser(user);
		localStorage.setItem('credentials', encodedUser);
	}

	saveUsername(username) {
		const encodedUsername = window.btoa(`${username}`);
		localStorage.setItem('username', encodedUsername);
	}

	getUsername() {
		return window.atob(localStorage.getItem('username'));
	}

	hasLoggedUser() {
		return this.getToken()
	}

	logout() {
		localStorage.removeItem('credentials');
		localStorage.removeItem('token')
	}

	_encodeUser(user) {
		return window.btoa(`${user.username}:${user.password}`);
	}

	_decodeUser(encodedUser) {
		const userString = window.atob(encodedUser);
		const parts = userString.split(':');

		if (parts.length === 2)
			return { username: parts[0], password: parts[1] };

		return {};
	}
}

export default new UserService();