import React, { Component } from 'react';
import { HttpService, UserService } from '../services';
import { serialMask } from '../services/mask';
import { NotificationManager } from 'react-notifications';

export default class History extends Component {

    constructor(props) {
        super(props);

        this.state = {
            serial: "",
            loading: false,
            historyFound: false,
            currentUpdateLog: {},
            lastUpdateLog: {},
            commandsConfirmed: 50,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.turnOnIgnition = this.turnOnIgnition.bind(this);
        this.turnOffIgnition = this.turnOffIgnition.bind(this);
        this.turnOnFastTrn = this.turnOnFastTrn.bind(this);
        this.turnOnNormalTrn = this.turnOnNormalTrn.bind(this);
        this.timer = null;
    }


    componentDidMount() {
        if (!UserService.hasLoggedUser())
            this.props.history.push('/login')
    }

    hasPeripheralCAN = (peripherals) => {
        if(peripherals.length === 0)
            return false;
        if(peripherals.filter(f => f.type === "CAN").length === 0)
            return false;
        return true;
    }

    handleGetUpdateStatus(vehicleId, type) {
        HttpService.getLastUpdateStatus(vehicleId, type)
            .then((response) => {
                if (response.data.lastUpdateLog !== undefined) {
                    this.setState({ historyFound: true, currentUpdateLog: response.data.lastUpdateLog });
                    if (this.state.currentUpdateLog.status !== ("SUCCESS" || "ERROR")) {
                        this.timer = setTimeout(() => this.handleGetUpdateStatus(vehicleId, type), 10000);
                    }
                    if (this.state.currentUpdateLog.status === ("WAITING_END_ACK" || "WAITING_CONFIRMATION")) {
                        this.setState({ commandsConfirmed: (response.data.commands.filter(command => command.status === "CONFIRMED").length / response.data.commands.length * 100).toFixed(0) });
                    }
                    else {
                        this.setState({ commandsConfirmed: 0 });
                    }
                }
                else {
                    NotificationManager.warning('Histórico não encontrado');
                }
                this.setState({ loading: false });
            });
    }

    handleSubmit = (event) => {

        event.preventDefault();

        var vehicleId = "";

        this.setState({ loading: true });

        HttpService.getVehicles(this.state.serial)
            .then((response) => {
                if (response.data.results.length === 0) {
                    NotificationManager.error('Veículo não encontrado');
                    this.setState({ loading: false });
                }
                else {
                    vehicleId = response.data.results[0].vehicleId;
                    HttpService.hasPeripheralCAN(this.state.serial)
                        .then((response) => {
                            if (response.data.results.length === 0 || response.data.results.filter(f => f.type === "CAN").length === 0) {
                                NotificationManager.error('Periférico CAN não encontrado');
                                this.setState({ loading: false });
                            }
                            else {
                                const type = 'CAN';
                                HttpService.getUpdateByStatus(vehicleId, type, "SUCCESS")
                                    .then((response) => {
                                        if (response.data.lastUpdateLog !== undefined) {
                                            this.setState({ lastUpdateLog: response.data.lastUpdateLog });
                                        }
                                        else {
                                            this.setState({
                                                lastUpdateLog: {
                                                    version: "unknown",
                                                    numberOfPackages: "unknown",
                                                }
                                            })
                                        }
                                        this.handleGetUpdateStatus(vehicleId, type);
                                    })
                            }
                        })
                }
            });
    }

    turnOnIgnition = (event) => {
        event.preventDefault();

        this.setState({ loading: true });

        HttpService.sendSms(this.state.serial, "!r3,138,2")
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Comando Enviado');
                }
                this.setState({ loading: false });
            })

    }

    turnOffIgnition = (event) => {
        event.preventDefault();

        this.setState({ loading: true });

        HttpService.sendSms(this.state.serial, "!r3,138,1")
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Comando Enviado');
                }
                this.setState({ loading: false });
            })

    }

    turnOnFastTrn = (event) => {
        event.preventDefault();

        this.setState({ loading: true });

        HttpService.sendSms(this.state.serial, "!rp,262,0,10")
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Comando Enviado');
                }
                this.setState({ loading: false });
            })

    }

    turnOnNormalTrn = (event) => {
        event.preventDefault();

        this.setState({ loading: true });

        HttpService.sendSms(this.state.serial, "!rp,262,0,60")
            .then((response) => {
                if (response.status === 200) {
                    NotificationManager.success('Comando Enviado');
                }
                this.setState({ loading: false });
            })

    }

    handleChange = (value) => event => {
        if (value === 'firmwareVersion') {
            this.setState({ [value]: event.target.value });
        }
        else {
            this.setState({ [value]: serialMask(event.target.value) });
        }
    }

    render() {
        return (
            <React.Fragment>
                <form className="needs-validation" onSubmit={this.handleSubmit}>
                    <div className="form-row ">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="validationTooltip02">Periférico</label>
                            <select className="custom-select" value={this.state.peripheral} onChange={this.handleChange('peripheral')}>
                                <option key="CAN" value="CAN">CAN</option>
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="validationTooltip02">Serial</label>
                            <input type="text" className="form-control" id="validationTooltip01" required value={this.state.serial} onChange={this.handleChange('serial')} />
                        </div>
                    </div>
                    {this.state.historyFound ? (
                        <div className="card" style={{ marginBottom: "20px" }}>
                            <div className="card-header">
                                Última Atualização
                            </div>
                            <div className="card-body">
                                <div className="card-title row">
                                    {this.state.currentUpdateLog.status === "SUCCESS" ?
                                        (
                                            <React.Fragment>
                                                <div className="col-sm-3">Versão atual de firmware:</div>
                                                <div className="col-sm-3 ">{this.state.currentUpdateLog.version} ({this.state.currentUpdateLog.numberOfPackages} páginas)</div>
                                            </React.Fragment>
                                        ) :
                                        (
                                            <React.Fragment>
                                                <div className="col-sm-3">Versão atual de firmware:</div>
                                                <div className="col-sm-3 ">{this.state.lastUpdateLog.version} ({this.state.lastUpdateLog.numberOfPackages} páginas)</div>
                                                <div className="col-sm-3">Nova versão de firmware:</div>
                                                <div className="col-sm-3 ">{this.state.currentUpdateLog.version} ({this.state.currentUpdateLog.numberOfPackages} páginas)</div>
                                            </React.Fragment>
                                        )
                                    }
                                </div>
                                <div className="card-title row">
                                    <div className="col-sm-3">Status:</div>
                                    {this.state.currentUpdateLog.status === "SUCCESS" ? (<div className="col-sm-6 text-success">Sucesso</div>) : (null)}
                                    {this.state.currentUpdateLog.status === "ERROR" ? (<div className="col-sm-6 text-danger">Erro</div>) : (null)}
                                    {this.state.currentUpdateLog.status === "WAITING_START_ACK" ? (<div className="col-sm-6 text-primary">Esperando o primeiro Ack (219)</div>) : (null)}
                                    {this.state.currentUpdateLog.status === "WAITING_END_ACK" ? (<div className="col-sm-6 text-primary">Esperando o último Ack (224)</div>) : (null)}
                                    {this.state.currentUpdateLog.status === "WAITING_CONFIRMATION" ? (<div className="col-sm-6 text-primary">Esperando a confirmação de atualização (221)</div>) : (null)}
                                </div>
                                <div className="card-title row">
                                    <div className="col-sm-3">Data do inicio da atualização</div>
                                    <div className="col-sm-3">{new Intl.DateTimeFormat("pt-BR", {
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                        year: "2-digit",
                                        month: "2-digit",
                                        day: "2-digit"
                                    }).format(Date.parse(this.state.currentUpdateLog.startProcess))}</div>
                                    <div className="col-sm-3">Data da útima atualização</div>
                                    <div className="col-sm-3">{new Intl.DateTimeFormat("pt-BR", {
                                        hour: "numeric",
                                        minute: "numeric",
                                        second: "numeric",
                                        year: "2-digit",
                                        month: "2-digit",
                                        day: "2-digit"
                                    }).format(Date.parse(this.state.currentUpdateLog.lastUpdate))}</div>
                                </div>
                                {this.state.currentUpdateLog.status === "SUCCESS" ?
                                    (null) :
                                    (
                                        <div className="card-title row">
                                            <div className="col-sm-11">
                                                <div className="progress">
                                                    <div className={"progress-bar progress-bar-striped progress-bar-animated " + (this.state.commandsConfirmed < 33 ? 'bg-warning ' : '') + (this.state.commandsConfirmed >= 33 && this.state.commandsConfirmed < 66 ? 'bg-info ' : '') + (this.state.commandsConfirmed >= 66 ? 'bg-success ' : '')} role="progressbar" aria-valuemin="0" aria-valuemax="100" style={{ width: `${this.state.commandsConfirmed}%` }}></div>
                                                </div>
                                            </div>
                                            <div className="col-sm-1">
                                                {this.state.commandsConfirmed}%
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="card-title row float-right" style={{ "marginRight": "20px" }}>
                                    <button onClick={this.turnOnIgnition} className="btn btn btn-primary " style={{ "marginLeft": "20px", "marginTop": "20px" }} disabled={this.state.loading || this.state.serial.length !== 10} >
                                        {(this.state.loading === true) ? (<div><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Carregando...</div>) : (<div>Ligar Ignição</div>)}
                                    </button>
                                    <button onClick={this.turnOffIgnition} className="btn btn btn-primary " style={{ "marginLeft": "20px", "marginTop": "20px" }} disabled={this.state.loading || this.state.serial.length !== 10} >
                                        {(this.state.loading === true) ? (<div><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Carregando...</div>) : (<div>Desligar Ignição</div>)}
                                    </button>
                                    <button onClick={this.turnOnFastTrn} className="btn btn btn-primary " style={{ "marginLeft": "20px", "marginTop": "20px" }} disabled={this.state.loading || this.state.serial.length !== 10} >
                                        {(this.state.loading === true) ? (<div><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Carregando...</div>) : (<div>Transmissões Rápidas (10s)</div>)}
                                    </button>
                                    <button onClick={this.turnOnNormalTrn} className="btn btn btn-primary " style={{ "marginLeft": "20px", "marginTop": "20px" }} disabled={this.state.loading || this.state.serial.length !== 10} >
                                        {(this.state.loading === true) ? (<div><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Carregando...</div>) : (<div>Transmissões Normais (1m)</div>)}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (null)
                    }
                    <button className="btn btn-secondary float-right" disabled={this.state.loading || this.state.serial.length !== 10} type="submit" value="Submit">
                        {(this.state.loading === true) ? (<div><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Carregando...</div>) : (<div>Exibir</div>)}
                    </button>
                </form>

            </React.Fragment>
        );
    }
}