import React, { Component } from 'react';
import NavBar from './ui/NavBar';
import Sidebar from './ui/Sidebar';

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      sidebarState: "d-flex",
      spanState: "fa fa-indent"
    }
  }

  render() {
    return (
      <React.Fragment>
        <NavBar spanState ={this.state.spanState} toggleSidenav={this.toggleSidenav.bind(this)} />
        <div className={this.state.sidebarState} id="wrapper">
          <Sidebar closeNav={this.closeNav.bind(this)} />
          <div id="page-content-wrapper">
            <main role="main" className="container mt-3">
              {this.props.children}
            </main>
          </div>
        </div>
      </React.Fragment>
    );
  }

  closeNav() {
    this.setState({
      "sidebarState": "d-flex",
      "spanState": "fa fa-indent"
    });
  }

  toggleSidenav() {
    var css = (this.state.sidebarState === "d-flex") ? "d-flex toggled" : "d-flex";
    this.setState({ "sidebarState": css });
    css = (this.state.spanState === "fa fa-indent") ? "fa fa-outdent" : "fa fa-indent";
    this.setState({ "spanState": css });
  }

}
export default App;