import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom'
import logo from '../assets/trixlog.png'
import { UserService } from '../services';
import userService from '../services/userService';

export default class NavBar extends Component {

    constructor(props) {
        super(props);
        this.state = { username: userService.getUsername() };
    }

    toggleSidenav() {
        this.props.toggleSidenav()
    }

    logout = (event) => {
        UserService.logout();
        return <Redirect to='/login' />
    }

    componentDidMount() {
        userService.getUsername();
    }

    render() {
        return (
            <nav className="navbar navbar-expand-md navbar-light border-bottom" style={{ backgroundColor: "#36AA57" }}>
                <button className="navbar-toggler navbar-light" type="button" data-toggle="collapse" onClick={this.toggleSidenav.bind(this)}>
                    <span className={this.props.spanState} style={{ backgroundColor: "#36AA57", borderColor: "#36AA57", color: "#ffffff" }}></span>
                </button>

                <Link to="/upload"><img src={logo} id="menu-toggle" style={{ width: '120px' }} alt=""></img></Link>

                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="fa fa-list" style={{ backgroundColor: "#36AA57", borderColor: "#36AA57", color: "#ffffff" }}></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto mt-2 mt-lg-0">
                        <li className="nav-item active">
                            <Link to='/login' style={{ textDecoration: 'none', color: "#ffffff" }}>{this.state.username}<button className="btn" id="logout"><span className="fa fa-sign-out" style={{ backgroundColor: "transparent", borderColor: "#36AA57", color: "#ffffff" }}></span></button></Link>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}
