import React, { Component } from 'react';
import { HttpService, UserService } from '../services';
import { serialMask } from '../services/mask';
import { NotificationManager } from 'react-notifications';

export default class Update extends Component {

    constructor(props) {
        super(props);

        this.state = {
            versions: [{ version: "Firmware Indisponíveis" }],
            firmwareVersion: "Firmware Indisponíveis",
            serial: "",
            loading: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (!UserService.hasLoggedUser()) {
            this.props.history.push('/login')
        }

        HttpService.getVersions()
            .then((response) => {
                if (response.data.length > 0) {
                    this.setState({
                        versions: response.data.sort((a, b) => (Number(a.version) > Number(b.version))),
                        firmwareVersion: response.data[0].version
                    });
                }
            });
    }

    hasPeripheralCAN = (peripherals) => {
        if(peripherals.length === 0)
            return false;
        if(peripherals.filter(f => f.type === "CAN").length === 0)
            return false;
        return true;
    }

    handleSubmit = (event) => {

        event.preventDefault();

        var vehicleId = "";

        this.setState({ loading: true });

        HttpService.getVehicles(this.state.serial)
            .then((response) => {
                if (response.data.results.length === 0) {
                    NotificationManager.error('Veículo não encontrado');
                    this.setState({ loading: false });
                }
                else {
                    vehicleId = response.data.results[0].vehicleId;
                    HttpService.hasPeripheralCAN(this.state.serial)
                        .then((response) => {
                            if (response.data.results.length === 0 || response.data.results.filter(f => f.type === "CAN").length === 0) {
                                NotificationManager.error('Periférico CAN não encontrado');
                                this.setState({ loading: false });
                            }
                            else {
                                const type = 'CAN';
                                HttpService.updateFirmware(vehicleId, this.state.firmwareVersion, type)
                                    .then(() => {
                                        NotificationManager.success('Comando enviado!');
                                        this.setState({ loading: false });
                                    });
                            }
                        })
                }
            });
    }

    handleChange = (value) => event => {
        if (value === 'firmwareVersion') {
            this.setState({ [value]: event.target.value });
        }
        else {
            this.setState({ [value]: serialMask(event.target.value) });
        }
    }

    render() {
        return (
            <React.Fragment>
                <form className="needs-validation" onSubmit={this.handleSubmit}>
                    <div className="form-row ">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="validationTooltip02">Versão</label>
                            <select className="custom-select" value={this.state.firmwareVersion} onChange={this.handleChange('firmwareVersion')}>
                                {this.state.versions.map(item => (
                                    <option key={item.version} value={item.version}>{item.version}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="validationTooltip02">Serial</label>
                            <input type="text" className="form-control" id="validationTooltip01" required value={this.state.serial} onChange={this.handleChange('serial')} />
                        </div>
                    </div>
                    <button className="btn btn-secondary float-right" disabled={this.state.loading || this.state.serial.length !== 10 || this.state.firmwareVersion === "Firmware Indisponíveis"} type="submit" value="Submit">
                        {(this.state.loading === true) ? (<div><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Carregando...</div>) : (<div>Atualizar</div>)}
                    </button>
                </form>
            </React.Fragment>
        );
    }
}
