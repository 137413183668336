import React, { Component } from 'react';
import { UserService, HttpService } from '../services';
import { firmwareMask } from '../services/mask';
import { NotificationManager } from 'react-notifications';

export default class Upload extends Component {

    constructor(props) {
        super(props)
        this.state = {
            fileName: "Arquivo",
            file: React.createRef(),
            firmware: {},
            firmwareVersion: "",
            peripheral: "CAN",
            loading: false,
        }
    }

    componentDidMount() {
        if (!UserService.hasLoggedUser())
            this.props.history.push('/login')

    }

    onFileSelected = (event) => {
        if (event.target.files[0].name.indexOf(".hex", -4) !== -1) {
            this.setState({
                fileName: event.target.files[0].name,
                file: event.target.files[0]
            })
        }
    }

    handleChange = (value) => event => {
        if (value === 'peripheral') {
            this.setState({ [value]: event.target.value });
        }
        else {
            this.setState({ [value]: firmwareMask(event.target.value) });
        }
    }


    handleSubmit = (event) => {
        event.preventDefault();
        const firmware = new FormData();
        firmware.append('firmware', this.state.file);
        this.setState({loading: true});

        HttpService.getVersions()
            .then((response) => {
                const result = response.data.find((element) => {
                    return this.state.firmwareVersion === element.version
                });
                if (result !== undefined) {
                    NotificationManager.error('Firmware ' + this.state.firmwareVersion + ' já existe');
                    this.setState({loading: false});
                }
                else {
                    HttpService.uploadFirmware(firmware, this.state.firmwareVersion, this.state.peripheral)
                        .then((response) => {
                            NotificationManager.success('Firmware ' + this.state.firmwareVersion + ' enviado');
                            this.setState({loading: false});
                        });
                }
            });


    }

    render() {
        return (
            <React.Fragment>
                <form className="needs-validation" noValidate onSubmit={this.handleSubmit}>
                    <div className="form-row">
                        <div className="col-md-6 mb-3">
                            <label htmlFor="validationTooltip01">Versão</label>
                            <input type="number" className="form-control" id="validationTooltip01" value={this.state.firmwareVersion} required onChange={this.handleChange('firmwareVersion')} />
                        </div>
                        <div className="col-md-6 mb-3">
                            <label htmlFor="validationTooltip02">Periférico</label>
                            <select className="custom-select" value={this.state.peripheral} onChange={this.handleChange('peripheral')}>
                                <option key="CAN" value="CAN">CAN</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="input-group col-md-12 mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text" id="inputGroupFileAddon01">Upload</span>
                            </div>
                            <div className="custom-file">
                                <input ref={this.file} onChange={this.onFileSelected} type="file" className="custom-file-input" id="inputGroupFile01"
                                    aria-describedby="inputGroupFileAddon01" required accept=".hex" />
                                <label className="custom-file-label" htmlFor="inputGroupFile01">{this.state.fileName}</label>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-secondary float-right" disabled={this.state.loading || this.state.file.current === null || this.state.firmwareVersion.length === 0} type="submit">
                        {(this.state.loading === true) ? (<div><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Carregando...</div>) : (<div>Enviar</div>)}
                    </button>
                </form>
            </React.Fragment>
        );
    }
}